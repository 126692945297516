import { graphql } from "gatsby";
import * as React from "react";
import { WmkSeo } from "wmk-seo";
import Layout from "../components/layout/MainLayout";
import get from "lodash/get";
import { Col, Container, Row } from "react-bootstrap";
import { Typography } from "../components/ui/Typography";

const CenteredCol = ({ children }) => (
  <Col style={{ display: "flex", justifyContent: "center" }}>{children}</Col>
);

const NotFoundPage = ({ data }) => (
  <Layout>
    <WmkSeo.Meta
      title={"404: Page Not Found"}
      siteTitle={get(data, `site.meta.title`)}
      baseUrl={get(data, `site.meta.baseUrl`)}
      path={""}
      slug={"/404"}
      description={get(data, `site.meta.description`)}
      twitterHandle={get(data, `site.meta.twitterHandle`)}
      ogImage={null}
      twitterImage={null}
    />
    <Container>
      <Row className="flex-column" style={{ padding: "2rem" }}>
        <CenteredCol>
          <Typography.H1>404: Not Found</Typography.H1>
        </CenteredCol>
        <CenteredCol>
          <Typography.P>
            You just hit a route that doesn&#39;t exist.
          </Typography.P>
        </CenteredCol>
      </Row>
    </Container>
  </Layout>
);

export default NotFoundPage;

export const query = graphql`
  {
    site {
      ...NodeSiteMetaFields
    }
  }
`;
